const menu = {
  data: [
    {
      title: '待处理任务',
      link: 'pendingTask',
      route_name: 'pendingTask',
      icon_class: 'el-icon-s-check',
      menu_id: 1,
      id: 1,
      parent_permission_id: 0
    },
    {
      title: '录入病人',
      link: 'enterPatient',
      route_name: 'enterPatient',
      icon_class: 'el-icon-user-solid',
      menu_id: 3,
      id: 3,
      parent_permission_id: 2
    },
    {
      title: '随访管理',
      link: 'followupManage',
      route_name: 'followupManage',
      icon_class: 'el-icon-s-order',
      menu_id: 2,
      id: 2,
      parent_permission_id: 1
    }
    // {
    //   title: '添加医生',
    //   link: 'addDoctor',
    //   route_name: 'addDoctor',
    //   icon_class: 'el-icon-s-custom',
    //   menu_id: 4,
    //   id: 4,
    //   parent_permission_id: 3
    // }
  ]
}
export default menu

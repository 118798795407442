import { render, staticRenderFns } from "./TheSlide.vue?vue&type=template&id=54184842&scoped=true&"
import script from "./TheSlide.vue?vue&type=script&lang=js&"
export * from "./TheSlide.vue?vue&type=script&lang=js&"
import style0 from "./TheSlide.vue?vue&type=style&index=0&id=54184842&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54184842",
  null
  
)

export default component.exports
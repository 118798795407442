<template>
  <el-breadcrumb class="Breadcrumb" separator="/">
    <el-breadcrumb-item
      :to="{name: route.name}"
      v-for="(route, index) in breadcrumbList"
      :key="index"
    >
      {{route.meta.title}}
    </el-breadcrumb-item>
  </el-breadcrumb>
</template>

<script>
export default {
  data () {
    return {
      breadcrumbList: []
    }
  },

  mounted () {
    this.breadcrumbList = this.$route.matched.slice(1).map(route => route)
    console.log(this.breadcrumbList)
  },

  watch: {
    $route (val) {
      this.breadcrumbList = val.matched.slice(1).map(route => route)
    }
  }
}
</script>

<style lang="scss" scoped>
.Breadcrumb {
  padding: 0 20px;
  background-color: white;
}
::v-deep.el-breadcrumb__item:last-child .el-breadcrumb__inner{
    font-size: 14px;
    font-weight: 500;
    color: #131414;
    display: flex;
    align-items: center;
    &::before {
      content: ' ';
      display: inline-block;
      width: 4px;
      height: 16px;
      background-color: #00a2ff;
      margin-right: 4px;
      border-radius: 2px;
    }
}
</style>
